import React, { useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import CustomPopup from "../CustomPopup/CustomPopup";

const Header = () => {
  let data = localStorage.getItem("UserInfo")
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  const [showModal,setShowModal] = useState(false)
  const closeModal = ()=>{
    setShowModal(false)
  }
  const openModal = () =>{
    setShowModal(true)
  }

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg navbar-black bg-black container ${
          collapsed ? "" : "expanded"
        }`}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <h2 className="text-white log-name">TRUFLIX</h2>
          </a>
          <button
            className={`navbar-toggler ${collapsed ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!collapsed}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon top-bar"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${collapsed ? "" : "show"}`}
            id="navbarNav"
          >
            <ul className="navbar-nav m-auto">
              <li className="nav-item  trueflix-nav">
                <a className="nav-link" 
                  onClick={()=>{
                    
                      // navigate("/movie-list")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link" 
                  onClick={()=>{
                    
                      // navigate("/movie-list")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link" 
                  onClick={()=>{
                    
                      // navigate("/movie-list")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link" 
                  onClick={()=>{
                    
                      // navigate("/movie-list")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link" 
                  onClick={()=>{
                    
                      // navigate("/movie-list")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link" 
                  onClick={()=>{
                    
                      // navigate("/movie-list")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link" 
                  onClick={()=>{
                    
                      navigate("/movie-list")
                    
                  }}
                >
                  MOVIES
                </a>
              </li>

              <li className="nav-item  trueflix-nav">
                <a className="nav-link" 
                onClick={()=>{
                  
                    navigate("/shows-list")
                  
                }}>
                  TV
                </a>
              </li>

              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  onClick={()=>{
                    
                      navigate("/event-highlights")
                    
                  }}
                >
                  EVENTS
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  onClick={()=>{
                    
                      navigate("/event-highlights")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  onClick={()=>{
                    
                      navigate("/event-highlights")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  onClick={()=>{
                    
                      navigate("/event-highlights")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  onClick={()=>{
                    
                      navigate("/event-highlights")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  onClick={()=>{
                    
                      navigate("/event-highlights")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  onClick={()=>{
                    
                      navigate("/event-highlights")
                    
                  }}
                >
                  
                </a>
              </li>
              <li className="nav-item  trueflix-nav">
                <a className="nav-link"
                  onClick={()=>{
                    
                      navigate("/event-highlights")
                    
                  }}
                >
                  
                </a>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto d-lg-none" >
              {" "}
              {/* Show only on mobile (extra small to large) */}
              <li className="nav-item">
                {data && <a className="nav-link"
                onClick={()=>{
                    if(data == null){
                      navigate("/sign-up")
                    }
                    else{

                      navigate("/user-dashboard")
                    }
                  
                }}
                >
                  ACCOUNT
                </a>}
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={()=>{
                  if (data != null) {
                    localStorage.removeItem("UserInfo")
                    localStorage.removeItem("UserToken")
                    navigate("/login");
                  } else if (data == null) {
                    navigate("/login")
                  }
                }}>
                  {data == null ? "SIGN IN" : "SIGN OUT"}
                  
                </a>
              </li>
            </ul>
            <ul className="navbar-nav ml-2 d-none d-lg-flex">
              {" "}
              {/* Hide on mobile (extra small to large) */}
             <li className="nav-item">
                {data ? <a className="nav-link"
                  onClick={()=>{
                    if(data == null){
                      navigate("/sign-up")
                    }
                    else{

                      navigate("/user-dashboard")
                    }
                  
                }}
                >
                  ACCOUNT
                </a> : <a className="nav-link" style={{color:"black",opacity:"0",cursor:"default"}}>account</a>}
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={()=>{
                  if (data != null) {
                    localStorage.removeItem("UserInfo")
                    localStorage.removeItem("UserToken")
                    navigate("/login");
                  } else if (data == null) {
                    navigate("/login")
                  }
                }}>
                {data == null ? "SIGN IN" : "SIGN OUT"}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <CustomPopup closeModal={closeModal} showModal={showModal} />
    </div>
  );
};

export default Header;
