import * as React from "react";
import OtpForm from "../../components/OtpForm/OtpForm";
import "./../AccessCodeEntryPage/AccessCodeEntryPage.css";
// import Header from "../../components/Header/Header";
import Header from "../../components/HeaderPricing/Header";
import CustomHeading from "../../components/CustomHeading/CustomHeading";
import CustomSubheading from "../../components/CustomSubHeading/CustomSubHeading";
import "./../MembershipConfirmationPage/MembershipConfirmation.css";
import "./ReferralAccessCodeProvidedPage.css";
import { useLocation, useNavigate } from "react-router-dom";
export default function ReferralAccessCodeProvidedPage(props) {
  function getMonthName(monthIndex) {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthIndex];
  }
  
  const threeMonthsLater = new Date();
threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);
const laterMonthName = getMonthName(threeMonthsLater.getMonth()).toUpperCase()
const laterDate = threeMonthsLater.getDate();
  const headingText = `SHARE THIS CODE WITH 3 PEOPLE BY ${laterMonthName} ${laterDate}.`;
  const subheadingText =
    "To stay a Truflix member, you must refer three people within three months.";
  const navigate = useNavigate();
  const data = localStorage.getItem("UserInfo")
  // console.log(JSON.parse(data))
  let userData = JSON.parse(data)
  const today = new Date();
  const location = useLocation()
  let access_code = location?.state?.prevData?.subsData?.data?.user?.access_code

// Add 3 months to today's date

  return (
    <>
      <Header />

      <div className="access-code-1">
        <div className="access-code-2">
          <CustomHeading width={"60%"} headingText={headingText} />
          <CustomSubheading width={"50%"} subheadingText={subheadingText} />
          <h1 className="referral-code">{access_code}</h1>
          <div className="otp-form">
            <button
              className="no-response"
              onClick={() => navigate("/user-dashboard")}
            >
              GOT IT!
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
