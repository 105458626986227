import * as React from "react";
import OtpForm from "../../components/OtpForm/OtpForm";
import "./../AccessCodeEntryPage/AccessCodeEntryPage.css";
// import Header from "../../components/Header/Header";
import Header from "../../components/HeaderPricing/Header";
import CustomHeading from "../../components/CustomHeading/CustomHeading";
import CustomSubheading from "../../components/CustomSubHeading/CustomSubHeading";
import "./MembershipConfirmation.css";
import { useLocation, useNavigate } from "react-router-dom";
export default function MembershipConfirmationPage(props) {
  const location = useLocation()
  const headingText = "YOU ARE NOW A TRUFLIX MEMBER!";
  const subheadingText =
    `This code is only available to people residing in ${location.state.country}.`;
  const navigate = useNavigate();
  // console.log("location",location.state)
  return (
    <>
      <Header />

      <div className="access-code-1">
        <div className="access-code-2">
          <CustomHeading width={"50%"} headingText={headingText} />
          <CustomSubheading  width={"50%"} subheadingText={subheadingText} />

          <div className="otp-form">
            <button
              className="no-response"
              onClick={() => navigate("/referral",{state:{country:location.state.country,prevData:location.state}})}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
