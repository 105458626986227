import React, { useEffect, useState } from 'react'
import Header from '../../components/HeaderPricing/Header'
import { useLocation, useNavigate } from 'react-router-dom'
import Modal from 'react-modal';
import {
    CardElement,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js';
import { BASEURL } from '../../API/constants';
import Loader from "react-js-loader";
import StripeIcon from "../../Assets/Images/stripe.png"

const NoSuscriptionscreen = () => {
  let data = localStorage.getItem("UserInfo")
  let userData = JSON.parse(data)
  // console.log("UserInfo",userData.token.current_token)
  const location = useLocation()
  // console.log("location",location.state.data)
  // console.log("territory",location.state.data.territory)
  let accessToken = location.state.token
  const [clientSecret,setClientSecret] = useState()
  const [subId, setSubId] = useState()
  const [loading,setLoading] = useState(false)
  // console.log("userData",userData?.name)
  // const [accesToken,setAccessToken] = useState(userData?.accessToken)
    const customStyles = {
        content: {
          maxWidth: "400px",
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          height:"30%",
          width:"40%",
          display:"flex",
          alignItems:"center",
          flexDirection:"column",
          justifyContent:"center",
        },
      };
    const navigate = useNavigate()
    const [subscriptionData, setSubscriptionData] = useState(null);



  // const createSubscription = async (priceId) => {
  //   const response = await fetch(`${BASEURL}/stripe/subscription/create`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       price_id:priceId
  //     }),
  //   }).then(r =>{ 
  //     r.json()
  //   })
  //   .catch((err)=>console.log("Stripe API ERROR: ",err))
  //   console.log("truflix subscriptionId, clientSecret",subscriptionId, clientSecret)
  //   // openModal()
  //   // setSubscriptionData({ subscriptionId, clientSecret });
  // }

  const createSubscription = async (priceId) =>{
    console.log("accessToken",accessToken)
    try{
      const stripeResponse = await fetch(`${BASEURL}/stripe/subscription/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : "Bearer " + accessToken,
              Accept: "application/json"
            },
            body: JSON.stringify({
              price_id:priceId
            })
      })
      let data = await stripeResponse.json()
      console.log("stripeResponse Data",data)
      if(data?.status){
        if(data.message == "You Have Already Subscribed"){
          alert("You Have Already subscribed")
          navigate("/")
        }
        setClientSecret(data?.data?.clientSecret)
        setSubId(data?.data?.subscriptionId)
        console.log("data?.data?.subscriptionId",data?.data?.subscriptionId)
        openModal()
      }
      

    }catch(err){
      console.log("STRIPE API ERROR: ",err)
    }
  }



  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

//   function afterOpenModal() {
//     // references are now sync'd and can be accessed.
//     subtitle.style.color = '#f00';
//   }

  function closeModal() {
    setIsOpen(false);
  }

  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (e) => {
    setLoading(true)
    // e.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: userData?.name,
        }
      }
    });

    if(error) {
      // show error and collect new card details.
      console.log("STRIPE CARD ERROR: ",error?.message)
      return;
    }
    console.log("paymentIntent",paymentIntent);
    await updateUser(subId,paymentIntent.id)

  }


  const updateUser = async (subId,paymentId) =>{
    console.log("sub Id",subId)
    try{
      
      const response = await fetch(`${BASEURL}/stripe/subscription/payment-status`,{
        method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : "Bearer " + accessToken,
              Accept: "application/json"
            },
            body: JSON.stringify({
              subscription_id:subId,
              payment_id:paymentId
            })
      })
      let data = await response.json()
      console.log("update user Data",data?.data?.user)
      if(data.status){
        // alert("Payment successful")
        console.log("payment Response", data)
        localStorage.setItem(
          "UserToken",
          JSON.stringify(accessToken)
        );
        localStorage.setItem("UserInfo",JSON.stringify(data))
        closeModal()
        console.log("Modal Closed")
        setLoading(false)
        navigate("/subscription-success",{state:{country:location.state.data.territory,subsData:data}})
      }
    }catch(err){
      setLoading(false)
      console.log("ERROR in Updating subscription info: ",err)
    }
  }





//   console.log(price.id)

    return (
        <div 
        // onClick={()=>{
        //   if(modalIsOpen){
        //     closeModal()
        //   }
        // }}
        >
            <Header />

            <div className='container-fuild bg-drak'>
                <div className='row'>
                    <div className='col-lg-12 mt-5 h-100'>
                        <div className='main-price-container text-white'>
                        {/* <h2 className='text-center text-white mt-5 price-heading'>
                        $20 <span className='text-white month-tag'> PER<br/>Month</span><br></br>
                      
                        </h2> */}
                        <h1 className='text-center fw-700 mt-5' style={{fontSize:"60px"}}>  EMAIL VERIFIED.</h1>
                        <h5 className='text-center ' style={{color:"green",fontSize:"16px"}}>  COMPLETE PAYMENT TO START ENJOYING.</h5>
                        <p className='text-center text-white'>You will be charged USD 20 in the first month and then <br></br>  only $10 every month after that.  </p>
                        <p className='text-center text-white mt-5'> <button type="button" className="btn btn-light text-center suscribe-btn" 
                        onClick={
                          ()=>
                          createSubscription("price_1QKgeGEPUgIHhG9BYV9nbPKJ")
                          // openModal()
                          
                          }>CONTINUE TO PAYMENT</button></p>

                    </div>
                    </div>
                </div>

            </div>
            {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h3 style={{paddingBottom:"10px"}}>Payment Form</h3>
        <div style={{width:"100%",height:"50%",backgroundColor:"blue"}} className='payment__form__container'>
        <CardElement
          options={{
            // Adjust the style of Card Element here
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />

        </div>
        <button className="btn btn-light text-center suscribe-btn" onClick={()=>handleSubmit()}>
          Subscribe
        </button>
      </Modal> */}
      <div className='stripe__modal' style={{display:modalIsOpen?"block":"none"}}>
        <div className='close_icon_container'>
          <p onClick={()=>closeModal()}>x</p>
        </div>
        <div >
          <h4>ENTER CREDIT/DEBIT<br/>CARD DETAILS</h4>
        </div>
        <div style={{padding:"10px"}}>
        <CardElement
          options={{
            // Adjust the style of Card Element here
            style: {
              base: {
                fontSize: "16px",
                color: "#fff",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        </div>
        <div className='stripe__button_container' >
        {!loading ? <button className="btn btn-light text-center suscribe-btn mb-4" onClick={()=>handleSubmit()}>
          Subscribe
        </button> :(
          <Loader type="spinner-default" bgColor={"white"} color={"black"} title={""} size={50} />
        )}
        </div>
        <div className='stripe__button_container' style={{
          backgroundColor:"#111111",
          height:"26%",
          width:"100%",
          position:"absolute",
          bottom:"0",
          borderBottomLeftRadius:"10px",
          borderBottomRightRadius:"10px"
        }}>
          <p style={{color:"white"}}>Payment processed by</p>
          <img src={StripeIcon} style={{
            width:"70px",
            height:"70px",
            marginBottom:"20px",
            marginLeft:"10px",
            
          }}/>
        </div>
        
      </div>
      
        </div>
    )
}

export default NoSuscriptionscreen
