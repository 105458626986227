import React, { useContext, useEffect, useState } from "react";
import Header from "./../components/HeaderPricing/Header";
import ScrollContainer from "react-indiana-drag-scroll";
import { useNavigate } from "react-router-dom";
import { BASEURL } from "./../API/constants";
import Loader from "react-js-loader";
import {ReactComponent as Arrow} from "./../Assets/icons/Down.svg"
import { MovieSearchContext } from "./../App";

const WatchAllMovies = ({ showHeader }) => {
  const { movieSearchData, updateMovieSearch } = useContext(MovieSearchContext);
  console.log("movieSearchData ====", movieSearchData);
  const [movies, setMovies] = useState([]);
  const [movies1, setMovies1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [genreFilter, setGenreFilter] = useState("");
  const [search, setSearch] = useState(false);
  const [searchPage, setSearchPage] = useState(1);
  const [searchTotalPages, setSearchTotalPages] = useState(1);
  const [genres, setGenres] = useState();
  const [bestOfAll, setBestOfAll] = useState();
  const [recentlyTruflixed,setRecentlyTruflixed] = useState()



  useEffect(() => {
    if (movieSearchData?.searchTerm) {
      setSearchTerm(movieSearchData.searchTerm);
      setYearFilter(movieSearchData.yearFilter);
      setGenreFilter(movieSearchData.genreFilter);
      setSearchPage(movieSearchData.searchPage);
      setSearch(true);
      fetchSearchDataWithParams(
        movieSearchData.searchTerm,
        movieSearchData.yearFilter,
        movieSearchData.genreFilter,
        movieSearchData.searchPage
      );
    }else if(movieSearchData?.searchPage){
        setSearchTerm(movieSearchData.searchTerm);
      setYearFilter(movieSearchData.yearFilter);
      setGenreFilter(movieSearchData.genreFilter);
      setSearchPage(movieSearchData.searchPage);
      setSearch(true);
      fetchSearchDataWithParams(
        movieSearchData.searchTerm,
        movieSearchData.yearFilter,
        movieSearchData.genreFilter,
        movieSearchData.searchPage
      );
    }
  }, [movieSearchData]);

  const fetchSearchDataWithParams = async (term, year, genre, page) => {
    console.log("PAGE NUMBER",term, year, genre, page)
    try {
      setLoading(true);
      console.log(
        `Search URL: ${BASEURL}/entertainment/movies?search=${term}&year=${year}&genres=${genre}&page=${page}`
      );
      const response = await fetch(
        `${BASEURL}/entertainment/movies?search=${term}&year=${year}&genres=${genre}&page=${page}`
      );
      const data = await response.json();
      console.log("type of data", typeof data.data);
      setMovies1(data.data);
      setSearchTotalPages(data.last_page);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching search data:", error);
      setLoading(false);
    }
  };

  const fetchGenres = async () => {
    try {
      const response = await fetch(`${BASEURL}/entertainment/master/genres`);
      const data = await response.json();
      // console.log("GENRES", data?.data);
      setGenres(data?.data);
    } catch (err) {
      console.log("GENRES ERROR: ", err);
    }
  };



  useEffect(() => {
    fetchData();
    fetchGenres();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BASEURL}/entertainment/movies?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${searchPage}`
      );
      // console.log(
      //   `${BASEURL}/entertainment/get-categorized/movies?page=${currentPage}`
      // );
      const data = await response.json();
      setMovies1(data.data);
      setTotalPages(data.last_page);
      setSearchTotalPages(data.last_page)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchSearchData = async () => {
  //   try {
  //     console.log(
  //       `${BASEURL}/entertainment/movies?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${searchPage}`
  //     );
  //     setLoading(true);
  //     const response1 = await fetch(
  //       `${BASEURL}/entertainment/movies?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${searchPage}`
  //     );
  //     const data1 = await response1.json();
  //     console.log("Search API Response:", data1);
  //     setMovies1(data1.data);
  //     setSearchTotalPages(data1.last_page);
  //     setLoading(false);
  //     setSearch(true);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };

  const fetchSearchData = async () => {
    try {
      // console.log(
      //   `${BASEURL}/entertainment/movies?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${searchPage}`
      // );
      setLoading(true);
      const response1 = await fetch(
        `${BASEURL}/entertainment/movies?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${searchPage}`
      );
      const data1 = await response1.json();
      // console.log("Search API Response:", data1);
      setMovies1(data1.data);
      setSearchTotalPages(data1.last_page);

      setLoading(false);
      setSearch(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setSearchPage(1);
    fetchSearchDataWithParams(searchTerm, yearFilter, genreFilter, 1);
    fetchSearchData();
  };

  // useEffect(()=>{

  //     persistResponse()
  //   // }
  // },[])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchPageChange = async (pageNumber) => {
    // console.log("page number",pageNumber)
    setSearchPage(pageNumber);
    try {
      // console.log(
      //   `${BASEURL}/entertainment/movies?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${pageNumber}`
      // );
      setLoading(true);
      const response1 = await fetch(
        `${BASEURL}/entertainment/movies?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${pageNumber}`
      );
      const data1 = await response1.json();
      // console.log("Search API Response:",data1);
      setMovies1(Object.values(data1.data));
      setSearchTotalPages(data1.last_page);
      // updateMovieSearch({
      //   searchTerm: searchTerm,
      //   yearFilter: yearFilter,
      //   genreFilter: genreFilter,
      //   searchPage: pageNumber
      // });
      setLoading(false);
      setSearch(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };



  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1900; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1999 },
    (_, index) => 2000 + index
  );

  const MoviePoster = ({
    imageSrc,
    alt,
    imageNumber,
    movie,
    showImage,
    showTitle,
    title,
  }) => {
    const navigate = useNavigate();
    let data = localStorage.getItem("UserInfo");
    // console.log("title", title);
    return (
      <>
        {showImage && <p className="poster-title">{imageNumber}</p>}
        <img
          onClick={() => {
            if (data == null) {
              navigate("/get-subscription");
              
            } else {
              // navigate("/movies-details", {
              //   state: {
              //     movie_id: movie.id,
              //     movie_data: movie,
              //   },
              // });
              if (search) {
                updateMovieSearch({
                  searchTerm: searchTerm,
                  yearFilter: yearFilter,
                  genreFilter: genreFilter,
                  searchPage: searchPage,
                });
                navigate(`/movies-details/${movie.id}`);
              } else {
                updateMovieSearch({
                    searchTerm: searchTerm,
                    yearFilter: yearFilter,
                    genreFilter: genreFilter,
                    searchPage: searchPage,
                  });
                navigate(`/movies-details/${movie.id}`)
              };
            }
          }}
          className="movie-poster"
          src={imageSrc}
          alt={alt}
          loading="lazy"
        />
        {showTitle && (
          <p
            style={{
              color: "white",
              fontSize: "16px",
              paddingTop: "10px",
              textAlign: "center",
            }}
          >
            {title}
          </p>
        )}
      </>
    );
  };
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="container-fluid mt-5">
        <div className="row justify-content-center full-w align-items-center">
          <h2 className="text-center text-white">MOVIE MANIA</h2>
          <h6 className="text-center text-white mb-2 pb-5">BY TRUFLIX</h6>

          <div
            data-aos="fade-up"
            className="container d-flex align-items-center justify-content-center flex-wrap"
          >
            <div className="col-md-4 col-sm-12 mb-3 mx-1">
              <input
                type="text"
                className="form-control movies-field"
                placeholder="Search by Title"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-12 mb-3 mx-2">
              <select
                className="form-control movies-field"
                value={yearFilter}
                onChange={(e) => setYearFilter(e.target.value)}
              >
                <option value="">All Years</option>
                {getYearOptions()
                  .reverse()
                  .map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-2 col-sm-12 mb-3 mx-2">
              <select
                className="form-control movies-field"
                value={genreFilter}
                onChange={(e) => setGenreFilter(e.target.value)}
              >
             
                <option>All Genres</option>
   
                {genres
                  ?.sort((a, b) => a.title.localeCompare(b.title)) // Sort alphabetically by title
                  .map((i, index) => (
                    <option value={i?.title} key={index}>
                      {i?.title}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-1 col-sm-12 mb-3 mx-2">
              <button
                className="btn btn-primary search-btn-movies"
                onClick={handleSearch}
                style={{ width: "100%" }}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0291 2.90032C11.4491 -0.429677 5.83861 -0.229726 2.50861 3.35027C-0.821392 6.93027 -0.621197 12.5403 2.9588 15.8703C6.1388 18.8303 10.9191 19.0003 14.2791 16.4903L19.2488 21.1103L21.4393 18.7603L16.4686 14.1403C18.7286 10.6003 18.2091 5.85031 15.0291 2.89031V2.90032ZM13.7586 13.8203C11.3086 16.4503 7.18841 16.6003 4.55841 14.1503C1.92841 11.7003 1.77931 7.58031 4.22931 4.95031C6.67931 2.32031 10.7985 2.17029 13.4285 4.62029C16.0585 7.07029 16.2086 11.1903 13.7586 13.8203Z"
                    fill="#EDEDED"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* {loading ? (
            <Loader
              type="spinner-default"
              bgColor={"white"}
              color={"black"}
              title={""}
              size={50}
            />
          ) : (
            <> */}
            {loading ? (
                <Loader
                type="spinner-default"
                bgColor={"white"}
                color={"black"}
                title={""}
                size={50}
              />
            ) :
            <>
            <div className="search-results-grid" data-aos="fade-up">
                
                {movies1 && Object.keys(movies1).length > 0 ? (
                  Object.values(movies1).map((i, index) => (
                    <div className="grid-item" key={i.id || index}>
                      <MoviePoster
                        imageNumber={index + 1}
                        imageSrc={i.poster_image}
                        alt={i.slug}
                        movie={i}
                        showImage={false}
                        showTitle={true}
                        title={i?.title}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <h4 style={{ color: "white" }}>No movies found</h4>
                  </div>
                )}
              </div>
            </>
            }
              
              {/* Pagination for Search Results */}
              {/* <div className="row">
                <div className="col-md-12 text-center">
                  <nav>
                    <ul className="pagination pagination__container">
                      <li
                        className={`page-item ${
                          searchPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handleSearchPageChange(searchPage - 1)}
                        >
                          Prev
                        </button>
                      </li>
                      {Array.from({ length: searchTotalPages }, (_, i) => (
                        <li
                          key={i + 1}
                          className={`page-item ${
                            searchPage === i + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handleSearchPageChange(i + 1)}
                          >
                            {i + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          searchPage === searchTotalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handleSearchPageChange(searchPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div> */}
              <div className="row">
  <div className="col-md-12 text-center">
    <nav>
      <ul className="pagination pagination__container">
        {/* Previous Button */}
        <li
          className={`page-item ${searchPage === 1 ? "disabled" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handleSearchPageChange(searchPage - 1)}
          >
            Prev
          </button>
        </li>

        {/* Page Numbers with Ellipsis */}
        {Array.from({ length: searchTotalPages })
          .map((_, i) => i + 1)
          .filter((page) => {
            const minPage = Math.max(1, searchPage - 2);
            const maxPage = Math.min(searchTotalPages, searchPage + 2);

            // Show first, last, current +/- 2, and surrounding pages
            return (
              page === 1 ||
              page === searchTotalPages ||
              (page >= minPage && page <= maxPage)
            );
          })
          .reduce((acc, page, index, pages) => {
            // Add ellipsis between non-adjacent pages
            if (index > 0 && page !== pages[index - 1] + 1) {
              acc.push("...");
            }
            acc.push(page);
            return acc;
          }, [])
          .map((page, index) =>
            page === "..." ? (
              <li key={`dots-${index}`} className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            ) : (
              <li
                key={page}
                className={`page-item ${
                  searchPage === page ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handleSearchPageChange(page)}
                >
                  {page}
                </button>
              </li>
            )
          )}

        {/* Next Button */}
        <li
          className={`page-item ${
            searchPage === searchTotalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => handleSearchPageChange(searchPage + 1)}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  </div>
</div>

            
              
         
          {/* Recently truflixed */}
          
        </div>
        <footer className="footer">
          <div
            className="container"
            style={{
              borderTop: "0.5px solid gray",
              backgroundColor: "white",
              marginBottom: "60px",
            }}
          ></div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="footer-logo tex-left">
                  <img
                    src="/images/TRUFLIX-logo.svg"
                    alt="img"
                    className="logo-img"
                  />
                  <p className="text-white mt-2">
                    Truflix © 2024. All Rights Reserved..
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-dev">
                  <nav className="footer-nav footer-link-sec ">
                    <ul className="footer-links">
                      <a
                        href="/terms-of-use"
                        className="footer-link p-0"
                        style={{ lineHeight: "5px" }}
                      >
                        <li>TERMS AND CONDITIONS</li>
                      </a>
                      <a
                        href="/disclaimer"
                        className="footer-link"
                        style={{ lineHeight: "5px" }}
                      >
                        <li>DISCLAIMER</li>
                      </a>
                      <a
                        href="/privacy-policy"
                        className="footer-link"
                        style={{ lineHeight: "5px" }}
                      >
                        <li>TRUFLIX PRIVATE POLICY</li>
                      </a>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <style jsx>{`
            .main-container {
              width: 100%;
              background-color: #000;
              color: #fff;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 5vw 10vw; /* Using vw units for padding */
              font-family: Montserrat, sans-serif;
            }
            // .site-header {
            //   width: 100%;
            //   text-align:center
            // }
            // .site-header h1 {
            //   text-align:center;
            //   padding-top: 3em !important;

            // }
            .guide-description {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }

            // @media screen and (max-width: 1440px) {
            //   .site-header h1 {
            //     padding-top: 5em !important;
            //     padding-left: 1rem !important;
            //   }
            // }
            // @media (max-width: 991px) {
            //   .main-container {
            //     padding: 5vw; /* Adjust padding for smaller screens */
            //   }
            // }
            .site-title {
              width: 80%;
              font-size: 3.5vw; /* Using vw units for font size */
              font-weight: 700;
              margin: 0;
              text-align: center;
              padding-bottom: 10px;
              // margin-left: 24px;
            }
            @media (max-width: 991px) {
              .site-title {
                font-size: 25px; /* Adjust font size for smaller screens */
              }
            }
            .guide-description .description-text {
              width: 55%;
              margin-top: 10px;
              font-size: 1.3vw; /* Using vw units for font size */
              font-weight: 400;
              text-align: center;
              line-height: 25px !important;
              // margin-bottom: 1vw;
            }
            .guide-description .access-btn {
              background-color: rgba(237, 237, 237, 1);

              width: 48%;
              padding-top: 5px;
              padding-bottom: 5px;
              font-size: 16px;
              font-weight: 700;
              margin-top: 40px;
              cursor: pointer;
              border: none;
            }
            @media (max-width: 600px) {
              .accss-btn {
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: 2vw;
              }
              .site-title {
                width: 100%;
              }
            }
            @media (max-width: 991px) {
              .guide-description .access-btn {
                font-size: 4vw;
              }
              .guide-description .description-text {
                width: 70%;
                font-size: 16px;
                margin-bottom: 12px;
                line-height: 18px !important;
              }

              .footer-nav {
                margin-top: 20px;
              }
              .footer-nav .footer-link {
                color: #fff;
                text-decoration: none;
                margin-right: 1vw;
                font-size: 2.5vw;
                margin-top: 100px;
              }
            }
            .footer {
              margin-top: 6vw; /* Using vw units for margin */
              font-size: 1vw; /* Using vw units for font size */
              font-weight: 700;
              text-align: center;
            }
            .footer-nav .footer-link {
              color: #fff;
              text-decoration: none;
              margin-right: 16px;
              margin-top: 100px;
            }
            // @media (max-width: 991px) {
            //   .footer {
            //     margin-top: 6.5vw; /* Adjust margin for smaller screens */
            //   }
            // }
          `}</style>
        </footer>
      </div>
      <style>{`
        .filter-input-container {
          border-radius: 5px;
        }

        .header {
          color: #fff;
          font-size: 2rem;
          font-weight: 700;
          line-height: 59px;
          text-align: left;
          padding-left: 9rem;
          padding-right: 5rem;
          padding-top: 2em;
          text-transform: uppercase;
          font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
        }

        .movies-container {
          display: flex;
          gap: 0.9em;
          padding: 2em;
          padding-left: 8rem;
          padding-right: 5rem;
          background: #000;
          width: 100%;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }

        .movies-container::-webkit-scrollbar {
          display: none;
        }

        .movie-poster {
          flex: 0 0 auto;
          width: 100%;
          max-width: 195px;
          aspect-ratio: 0.68;
          object-fit: cover;
          cursor: pointer;
          margin-right: 1rem;
        }

        .poster-title {
          font-size: 7rem !important;
          margin-top: -48px;
          color: #333333;
          font-weight: 700;
          font-family: "Montserrat";
        }

        .search-results-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 5.5rem;
          justify-content: center;
          padding: 1em;
        }
        .recently-added-grid{
          display: flex;
          flex-wrap: wrap;
          gap: 2.5rem;
          justify-content: flex-start;
          padding: 1em;
          margin-left:50px;
        }

        .grid-item {
          flex: 1 0 25%; /* Adjust the percentage to control the number of items per row */
          max-width: 15%;
          box-sizing: border-box;
          margin: 1em;
          text-align: center;
        }
          .grid-item-recent{
            flex: 1 0 20%; /* Adjust the percentage to control the number of items per row */
          max-width: 20%;
          box-sizing: border-box;
          margin: 1em;
          text-align: center;
          }

        @media screen and (max-width: 768px) {
          .movies-container {
            padding: 1em;
          }

          .header {
            font-size: 35px;
            line-height: 37px;
            padding: 60px 10px;
          }

          .movie-poster {
            max-width: 150px;
          }

          .grid-item {
            flex: 1 0 46%; /* Adjust the percentage for smaller screens */
            max-width: 46%;
          }
            .grid-item-recent{
              flex: 1 0 46%; /* Adjust the percentage for smaller screens */
            max-width: 46%;
            }
        }
        @media screen and (max-width: 768px) {
          .grid-item {
            flex: 1 0 30%; /* Adjust the percentage for smaller screens */
            max-width: 30%; /* 3 items per row on smaller screens */
          }
        }
      
        @media screen and (max-width: 425px) {
          .grid-item {
            flex: 1 0 46%; /* Adjust the percentage for smaller screens */
            max-width: 46%; /* 2 items per row on smaller screens */
          }
        }
      
        @media screen and (max-width: 400px) {
          .grid-item {
            flex: 1 0 100%; /* Single column on very small screens */
            max-width: 100%;
          }
        }

        @media screen and (max-width: 425px) {
          .header h1 {
            font-size: 16px;
          }

          .grid-item {
            flex: 1 0 100%; /* Single column on very small screens */
            max-width: 100%;
          }
        }

        @media screen and (max-width: 400px) {
          .header h1 {
            font-size: 12px;
          }
        }
      `}</style>
    </>
  );
};

export default WatchAllMovies;