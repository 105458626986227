import React, { useEffect, useState } from "react";
import Header from "../../components/HeaderPricing/Header";
import { useNavigate } from "react-router-dom";

const Subscriptionscreen = () => {
  const navigate = useNavigate();

  //   console.log(price.id)

  return (
    <div>
      <Header />

      <div className="container-fuild bg-drak">
        <div className="row">
          <div className="col-lg-12 mt-5 h-100">
            <div className="main-price-container text-white">
              <h3 className="text-center text-green mt-5 subscribe-heading">
                LIMITED TIME OFFER
              </h3>
              <h1 className="text-center fw-700" style={{fontSize:"60px"}}>ONLY $10 PER MONTH</h1>
              <h5 className="text-center fw-500" style={{fontSize:"24px"}}>
                PLUS $10 ONE TIME SIGN UP FEE
              </h5>
              <p className="text-center text-white">
                You will be charged $20 in the first month and then{" "}
              </p>
              <p
                className="text-center text-white"
                style={{ marginTop: "-12px" }}
              >
                only $10 every month after that.{" "}
              </p>
              <p className="text-center text-white mt-5">
                {" "}
                <button
                  type="button"
                  className="btn btn-light text-center suscribe-btn"
                  onClick={
                    () => navigate("/access-code-verification")
                    // navigate("/sign-up")
                  }
                >
                  SUBSCRIBE
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriptionscreen;
